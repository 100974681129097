import React from 'react'
import { Link } from 'gatsby'

import github from '../../content/thumbnails/github.png'

export default function Footer() {
  return (
    <footer className="footer flex">
      <section className="container">
        <nav className="footer-links">
          <Link to="/archives">English</Link>
          <Link to="/rss.xml">RSS</Link>
        </nav>
        <nav className="flex justify-center">
          <a
            href="https://github.com/dinever"
            title="Open-source on GitHub"
            target="_blank"
            rel="noopener noreferrer"
            className="img"
          >
            <img src={github} className="footer-img" alt="GitHub" />
          </a>
        </nav>
      </section>
    </footer>
  )
}
