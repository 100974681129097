import React from 'react'
import Helmet from 'react-helmet'

import favicon from '../../content/images/keyboard.png'
import Nav from './Nav'
import Footer from './Footer'

import '../style.css'
import '../new-moon.css'

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <link rel="shortcut icon" type="image/png" href={favicon} />
        <script src='https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-MML-AM_CHTML' async></script>
      </Helmet>
      <Nav />
      <main>{children}</main>
      <Footer />
    </>
  )
}
